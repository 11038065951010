<template>
  <v-form @submit.prevent="create">
    <!-- Controllers -->
    <div color="white" class="d-flex justify-end py-4 pr-6 floating-buttons">
      <v-btn color="primary" type="submit" dark depressed v-text="$t('pushNotifications.sendOrSchedule')" />
    </div>
    <!-- Body -->
    <v-row class="pa-6 bigScreenForm">
      <v-col md="8" sm="12">
        <v-row>
          <v-col cols="11">
            <h3 class="mb-3" v-text="$t('pushNotifications.target')" />
            <v-select
              v-show="targetTypeItems.length > 1"
              v-model="form.targetType"
              :error-messages="getFieldErrors('targetType')"
              :label="$t('common.type')"
              :items="targetTypeItems"
              outlined
              dense
              @blur="$v.form.targetType.$touch()"
              @change="form.targetEntities = []"
            />
            <v-autocomplete
              v-model="form.targetEntities"
              :label="$t('common.users')"
              outlined
              dense
              :items="targetItems"
              multiple return-object
              :error-messages="getFieldErrors('targetEntities')"
              @blur="$v.form.targetEntities.$touch()"
              @change="ev => form.targetType === 'project' && getActivities(ev)"
            />
            <v-select
              v-model="form.language"
              :error-messages="getFieldErrors('language')"
              :label="$t('common.language')"
              :items="locales"
              item-value="code"
              item-text="language"
              outlined
              dense
              hide-details="auto"
              @blur="$v.form.language.$touch()"
            />
            <v-checkbox
              v-model="form.isScheduled"
              :label="$t('pushNotifications.schedule')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11">
            <v-autocomplete
              :value="form.schedule.timezone"
              :label="$t('common.timeZone')"
              :items="timezoneItems"
              outlined
              dense
              :disabled="!form.isScheduled"
              :error-messages="getFieldErrors('schedule.timezone')"
              hide-details="auto"
              @blur="$v.form.schedule.timezone.$touch()"
              @input="selectTimezone"
            />
          </v-col>
          <v-col>
            <datetime-field
              :key="datetimeKey"
              v-model="form.schedule.datetime"
              :timezone="form.schedule.timezone"
              :disabled="!form.isScheduled"
              :min-datetime="new Date()"
              :max-datetime="new Date(Date.now() + 29 * 24 * 60 * 60 * 1000)"
              :error-messages="getFieldErrors('schedule.datetime')"
              @blur="$v.form.schedule.datetime.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11">
            <h3 class="mb-3" v-text="$t('common.content')" />
            <v-text-field
              v-model="form.title"
              :label="$t('common.title')"
              outlined
              dense
              :error-messages="getFieldErrors('title')"
              @input="$v.form.title.$touch()"
              @blur="$v.form.title.$touch()"
            />
            <v-autocomplete
              v-model="form.link"
              :label="$t('common.link')"
              outlined
              dense
              class="mb-6"
              :items="linkItems"
              item-text="name"
              return-object
              :error-messages="getFieldErrors('link')"
              @blur="$v.form.link.$touch()"
            />
            <v-textarea
              v-model="form.body"
              :label="$t('common.body')"
              outlined
              dense
              :error-messages="getFieldErrors('body')"
              @input="$v.form.body.$touch()"
              @blur="$v.form.body.$touch()"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import timezonesEnum from '@/enums/timezonesEnum'
import formUtils from '@/utils/mixins/formUtils'
import localeUtils from '@/utils/mixins/localeUtils'
import i18n from '@/plugins/i18n'

export default {
  name: 'Create',
  components: {
    DatetimeField: () => import('@/components/formFields/DatetimeField'),
  },
  mixins: [formUtils, localeUtils],
  props: {
    organizationId: { type: String, required: true },
    clusterId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  data() {
    return {
      timezoneItems: timezonesEnum,
      form: {
        targetType: null,
        targetEntities: [],
        title: null,
        link: null,
        body: null,
        isScheduled: false,
        schedule: { datetime: null, timezone: null },
        language: null,
      },
      targetProjectsActivities: [],
      datetimeKey: 0,
    }
  },
  computed: {
    ...mapGetters('organization', ['isUserAdmin', 'isGuild']),
    ...mapGetters('user', ['managerClusterIds', 'collabClusterIds', 'collabProjectIds']),
    ...mapGetters('project', ['isUserSubmanager']),
    viewEntityType({ clusterId, projectId }) {
      if (projectId) return 'project'
      if (clusterId) return 'cluster'
      return 'organization'
    },
    projects: ({ $store, viewEntityType }) => viewEntityType === 'organization'
      ? $store.getters['organization/projects'].filter(project => project.published && project.active)
      : $store.getters['cluster/projects'].filter(project => project.published && project.active),
    clusters: ({ $store }) => $store.getters['organization/clusters'].filter(c => c.published && c.active),
    targetTypeItems: ({ viewEntityType, isGuild, targetClusters, targetOrganization }) => [
      ...(viewEntityType === 'organization' && targetOrganization ? [{ value: 'organization', text: i18n.t('entityTypes.organization') }] : []),
      ...(['organization', 'cluster'].includes(viewEntityType) && targetClusters.length ? [{ value: 'cluster', text: i18n.t(`common.cluster${isGuild ? 'Guild' : ''}`) }] : []),
      { value: 'project', text: i18n.t('common.project') },
    ],
    targetOrganization: ({ viewEntityType, organizationId, $store, isUserAdmin }) => viewEntityType === 'organization' && isUserAdmin
      ? { id: organizationId, name: $store.getters['organization/data'].name, type: 'organization', text: i18n.t('pushNotifications.selectOptions.all') } : null,
    targetClusters({ clusters, viewEntityType, $store }) {
      const userClusters = (viewEntityType === 'organization' ? clusters : viewEntityType === 'cluster' ? [$store.getters['cluster/data']] : [])
        .filter(c => this.isUserAdmin ? true : this.managerClusterIds.includes(c.id))
      return userClusters.map(c => ({
        id: c.id,
        name: c.name,
        text: this.$t('pushNotifications.selectOptions.subscribersOf', [c.name]),
        type: 'cluster',
      }))
    },
    targetProjects({ projects, viewEntityType, clusterId, $store, clusters }) {
      const userProjects = viewEntityType === 'project' ? [$store.getters['project/data']] : projects
        .filter(p => this.isUserAdmin ? true : this.collabProjectIds.includes(p.id) || this.managerClusterIds.includes(p.clusterId))
        .filter(p => viewEntityType === 'cluster' ? p.clusterId === clusterId : true)
      return userProjects.map(project => {
        const longName = `${viewEntityType === 'organization' && project.clusterId ? `${clusters.find(c => c.id === project.clusterId).name} - ` : ''}${project.name}`
        return {
          id: project.id,
          name: project.name,
          longName,
          timezone: project.timezone,
          clusterId: project.clusterId,
          text: this.$t('pushNotifications.selectOptions.subscribersOf', [longName]),
          type: 'project',
        }
      })
    },
    targetItems({ form, targetOrganization, targetProjects, targetClusters }) {
      if (form.targetType === 'organization') return [targetOrganization]
      if (form.targetType === 'cluster') return targetClusters
      if (form.targetType === 'project') return targetProjects
      return []
    },
    linkItems({ form, targetOrganization, targetProjects, targetClusters, targetProjectsActivities, isGuild }) {
      if (form.targetType === 'organization') {
        return [
          { id: 'organizationDetail', type: 'organizationDetail', name: this.$t('pushNotifications.selectOptions.homeOf', [targetOrganization.name]) },
          ...targetClusters.map(cluster => ({ id: cluster.id, type: 'clusterDetail', name: cluster.name })),
          ...targetProjects.map(project => ({ id: project.id, type: 'projectDetail', name: project.longName })),
        ]
      }
      if (form.targetType === 'cluster') {
        return [...this.form.targetEntities.map(cluster => ({ id: cluster.id, type: 'clusterDetail', name: this.$t('pushNotifications.selectOptions.homeOf', [cluster.name]) })),
          ...targetProjects.filter(p => this.form.targetEntities.map(c => c.id).includes(p.clusterId)).map(project => ({ id: project.id, type: 'projectDetail', name: project.name })),
        ]
      }
      if (form.targetType === 'project') {
        return [...this.form.targetEntities.map(project => ({ id: project.id, type: 'projectDetail', name: this.$t('pushNotifications.selectOptions.homeOf', [project.name]) })),
          ...targetProjectsActivities.map(activity => ({ id: activity.id, type: 'activityDetail', name: activity.name })),
        ]
      }
      return null
    },
  },
  validations() {
    return {
      form: {
        targetType: { required },
        targetEntities: { required },
        title: { required, maxLength: maxLength(50) },
        link: { required },
        body: { required, maxLength: maxLength(128) },
        isScheduled: false,
        schedule: {
          datetime: { required: requiredIf(() => this.form.isScheduled) },
          timezone: { required: requiredIf(() => this.form.isScheduled) },
        },
        language: { required },
      },
    }
  },
  created() {
    this.form.schedule.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'Europe/Madrid'
    if (this.targetTypeItems.length === 1) this.form.targetType = this.targetTypeItems[0].value
    if (this.targetItems.length === 1) {
      this.form.targetEntities = [this.targetItems[0]]
      if (this.form.targetType === 'project') this.getActivities(this.form.targetEntities)
    }
  },
  methods: {
    async getActivities(projects) {
      // If a project is selected as the target/scope of the notification, we asyncronously get all the project's activities
      const activities = (await Promise.all(projects.map(project => this.$store
        .dispatch('project/readActivities', { organizationId: this.organizationId, projectId: project.id })))).flat()
      console.log(activities)
      this.targetProjectsActivities = activities
        .filter(activity => activity.published && activity.active)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    selectTimezone(timezone) {
      this.form.schedule.datetime = null
      this.datetimeKey += 1
      this.form.schedule.timezone = timezone
    },
    buildNotifications() {
      const { title, body, link: linkObj, targetType, targetEntities, language } = this.form
      const scope = targetType
      return targetEntities.map(entity => {
        // Calculate notification context
        const cluster = targetType === 'cluster' ? entity
          : targetType === 'project' && entity.clusterId ? (this.$store.getters['cluster/data'] || this.clusters.find(c => c.id === entity.clusterId))
            : (linkObj?.type === 'clusterDetail') ? linkObj : null
        const project = targetType === 'project' ? entity : (linkObj?.type === 'projectDetail') ? linkObj : null
        const context = {
          organizationId: this.organizationId,
          clusterId: cluster?.id ?? null,
          clusterName: cluster?.name ?? null,
          projectId: project?.id ?? null,
          projectName: project?.name ?? null,
          activityId: linkObj?.type === 'activityDetail' ? linkObj?.id : null,
        }
        // Get linkType
        const linkType = linkObj?.type ?? 'home'
        return { scope, context, data: { isCustom: true, title, body, linkType }, language }
      })
    },
    async create() {
      // Validate form
      if (!this.isFormValid()) return
      // Get schedule date (if any)
      const scheduledDate = this.form.isScheduled ? this.form.schedule.datetime : null
      // Build notifications
      const notifications = this.buildNotifications()
      // Create notifications
      this.runAsync(() => Promise.all(notifications.map(notification => this.$store.dispatch('pushNotifications/create', { notification, scheduledDate }))))
    },
  },
}
</script>

<style lang="scss" scoped>
.floating-buttons {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
